// import js dependencies
//import AjaxPost from "./ajax";
import Swipers from "./swiper";
import Menu from "./menu";
import BeforeAndAfterSliders from "./before-after";
// import Scroll from "./scroll";
import StickyNav from "./stickynav";
import ManageConsent from "./consent";
// import AOS from "aos";
// import "aos/dist/aos.css";

//Get Ajax Responses
jQuery(($) => {
    const swipers = new Swipers({});
    swipers.init();
    // const ajaxPost = new AjaxPost({});
    // ajaxPost.init();
    const menu = new Menu({});
    menu.init();
});

jQuery(function ($) {
    //Consent Magic footer pop-up
    $("body").on("click", ".tessellateConsent", function (event) {
        event.preventDefault();
        $(".cs-info-sticky-button").click();
    });

    $(".team-member-modal").on("show.bs.modal", function (event) {
        $(this)
            .prev()
            .find(".team-member-brief")
            .removeClass("team-member-brief--triggered");
    });

    $(".team-member-modal").on("hidden.bs.modal", function () {
        // Add a class to the target element
        $(this)
            .prev()
            .find(".team-member-brief")
            .addClass("team-member-brief--triggered");
        // $(".team-member-brief").css("transform", "");
    });

    window.addEventListener("resize", function () {
        $(".team-member-brief--triggered").removeClass(
            ".team-member-brief--triggered"
        );
    });
});

jQuery(($) => {
    const beforeAndAfterSliders = new BeforeAndAfterSliders({});
    beforeAndAfterSliders.init();
});

// jQuery(($) => {
//     const scroll = new Scroll({});
//     scroll.init();
// });

// Prevents scrolling on tablet & mobile when the menu is open
const stickyNav = new StickyNav({});
stickyNav.init();

// Setup ConsentMagic
const manageConsent = new ManageConsent({});
manageConsent.init();

// jQuery(($) => {
//     //aos settings global
//     AOS.init({
//         duration: 1200, // values from 0 to 3000, with step 50ms
//         easing: "ease",
//         offset: 0,
//     });
// });
