const $ = jQuery;

export default class BeforeAndAfterSliders {
    constructor(options = null) {}

    init() {
        this.__beforeAndAfter();
    }

    __beforeAndAfter() {
        const $beforeAndAfterElms = $(".js-before-and-after");
        const startWidth = 50; //8.87

        $beforeAndAfterElms.each(function () {
            var beforeParent = $(this).find(".js-before-parent");
            var afterParent = $(this).find(".js-after-parent");
            var labelBefore = $(this).find(".js-label-before");
            var labelAfter = $(this).find(".js-label-after");
            var handle = $(this).find(".js-handle");
            var sliderInput = $(this).find(".js-slider-input");

            // Set widths on setup
            sliderInput.val(startWidth);
            beforeParent.css("width", sliderInput.val() + "%");
            afterParent.css("width", 100 - sliderInput.val() + "%"); // invert % val for the after image
            handle.css("width", sliderInput.val() + "%");

            // Event handler for sliding
            sliderInput.on("input", function () {
                var value = $(this).val();
                beforeParent.css("width", value + "%");
                afterParent.css("width", 100 - value + "%");
                handle.css("width", value + "%");

                // Switch z-index states
                if (value < 50) {
                    beforeParent.css("z-index", "0");
                    afterParent.css("z-index", "1");
                    labelBefore.css("z-index", "0");
                    labelAfter.css("z-index", "1");
                } else if (value >= 50) {
                    beforeParent.css("z-index", "1");
                    afterParent.css("z-index", "0");
                    labelBefore.css("z-index", "1");
                    labelAfter.css("z-index", "0");
                }
            });

            // Custom event handler for reseting values/positions
            // sliderInput.on("reset", function () {
            //     sliderInput.val(startWidth);
            //     after.css("width", sliderInput.attr("data-start-value") + "%");
            //     handle.css("width", sliderInput.attr("data-start-value") + "%");
            // });
        });
    }
}
